import React, { useEffect, useState } from 'react';
import { PlaybookStickyFooterContainer, PlaybookStickyFooter, FooterNavigation } from '../styles';

const PlaybookDetailsHero = ({ sectionInfo, sections }) => {
  const { current_section, current_chapter, total_chapters, total_sections, splashPageSlug } =
    sectionInfo;

  const [showFooter, toggleShowFooter] = useState(false);

  const handleScroll = (e) => {
    const playbookHeroNav = document.getElementById('playbook-hero-nav');
    const bodyOffset = playbookHeroNav?.getBoundingClientRect();

    toggleShowFooter(bodyOffset?.top < 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <PlaybookStickyFooterContainer style={{ display: showFooter ? 'flex' : 'none' }}>
      <PlaybookStickyFooter>
        <FooterNavigation
          to={current_section !== 0 && sections[current_section - 1]?.seo?.pageUrl}
          disabled={current_section === 0}
          shadow={false}
          type={'secondary'}
          button
        >
          Previous
        </FooterNavigation>
        <FooterNavigation
          to={
            current_section + 1 === total_sections
              ? `${splashPageSlug}#${`chapter${current_chapter + 1}`}`
              : sections[current_section + 1].seo?.pageUrl
          }
          state={{ finishedChapter: current_chapter + 1 }}
          type="secondary"
          button
        >
          {current_section + 1 === total_sections ? 'Finish Chapter' : 'Next Section'}
        </FooterNavigation>
      </PlaybookStickyFooter>
    </PlaybookStickyFooterContainer>
  );
};

export default PlaybookDetailsHero;
