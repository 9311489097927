import styled, { css } from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';
import Button from 'components/Core/Button';

//Hero
export const StyledHero = styled(SectionLayout)`
  text-align: center;
  background-image: ${({ playbookHeroBG }) => playbookHeroBG && `url(${playbookHeroBG})`};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .section-layout--inner-container {
    padding-top: 300px !important;
    padding-bottom: 30px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 635px;

    @media (max-width: 1450px) {
      padding-top: 270px !important;
      min-height: 605px;
    }

    @media (max-width: 1199px) {
      padding-top: 230px !important;
      min-height: 565px;
    }
  }
`;

export const PlaybookDetailsContainer = styled.div`
  verticle-align: middle;
`;

export const PlaybookDetailsHeroKicker = styled.p`
  font-size: var(--font-size-13);
  color: white;
  margin-top: 0 !important;
  margin-bottom: 10px !important;
`;

export const PlaybookDetailsHeroTitle = styled.h1`
  color: white;
  max-width: 900px;
  margin: 10px auto !important;
`;

export const PlaybookDetailsHeroSubhead = styled.p`
  color: white;
  max-width: 675px;
  margin: auto;
  font-size: var(--font-size-16);
  @media (max-width: 575px) {
    margin: 10px;
  }
`;

export const PlaybookDetailsNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 90%;
  bottom: 0;
  @media (max-width: 575px) {
    margin-top: 40px !important;
    width: 100%;
  }
`;

export const PlaybookStickyFooterContainer = styled.div`
  background-color: var(--white);
  width: 100%;
  height: 90px;
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  z-index: 1000;
  border: 1px solid var(--grey-3);
  box-shadow: 0px 15px 40px rgba(11, 26, 33, 0.1);
  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const PlaybookStickyFooter = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  position: absolute;
  bottom: 0px;
  width: 90%;
  @media (max-width: 575px) {
    margin-top: 40px !important;
    width: 95%;
  }
`;

export const FooterNavigation = styled(Button)`
  line-height: normal !important;
  width: 166px;
  height: 48px;
  font-size: var(--font-size-16);
  border: none !important;
  margin-bottom: 20px;

  @media (max-width: 575px) {
    width: 135px;
    padding: 5px;
  }
`;

export const SectionNavigation = styled(Button)`
  line-height: normal !important;
  width: 166px;
  height: 48px;
  font-size: var(--font-size-16);
  margin-bottom: 30px;
  border: none !important;

  @media (max-width: 575px) {
    width: 135px;
    padding: 5px;
  }
`;

//Hero - end

//Content
export const ContentLayout = styled(SectionLayout)`
  .section-layout--inner-container {
    max-width: 900px !important;
    padding-top: 59px !important;
  }
`;

export const ContentKicker = styled.p`
  font-size: var(--font-size-13);
  color: var(--grey-8);
  margin-bottom: 12px !important;
`;

export const ContentTitle = styled.h2`
  font-size: var(--font-size-28) !important;
  margin-bottom: 20px !important;
`;

export const ContentNextButton = styled(Button)`
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 575px) {
    text-align: center;
    .next-section-title {
      display: none;
    }
  }
`;

//Content - end

//Table of Contents
export const TableOfContentsLayout = styled(SectionLayout)`
  background-color: var(--dark) !important;
`;

export const TableTitle = styled.h2`
  text-align: center;
  color: white;
  font-size: var(--font-size-28);
  margin-bottom: 45px;
`;

export const TableCard = styled.div`
  margin-bottom: 12px !important;
  position: relative;

  .table-card--inner-container {
    border-radius: 2px;
    background: var(--white);
    width: 100%;
    padding: 20px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  @media (max-width: 575px) {
    width: 100%;
    max-width: 100% !important;
    overflow: hidden;

    .table-card--inner-container {
      display: block;
      max-width: 100%;
    }
  }
`;

export const TableCardTitle = styled.h2`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-size-16) !important;
  margin-bottom: 0px;
  align-items: center;

  @media (max-width: 575px) {
    display: flex;
  }
`;

export const TableProgress = styled.p`
  display: flex;
  flex-direction: row;
  aling-self: flex-end;
  position: absolute;
  right: 20px;
  margin-bottom: 0px;

  .section-status {
    margin-right: 4px;
    height: 24px;
    width: 24px;

    @media (max-width: 575px) {
      margin-right: 2px;
    }
  }
  .section-status:first-child {
    margin-left: 20px;
    @media (max-width: 575px) {
      margin-left: 0px;
    }
  }
  .section-status:last-child {
    margin-right: 0px;
  }
  @media (max-width: 575px) {
    position: initial;
    max-width: 100%;
    flex-wrap: wrap;

    img {
      margin-top: 8px;
    }
  }
`;

//Table of Contents - End

export const ToC = styled.div`
  margin: 20px 0 37px;
  padding: 40px 40px 28px;
  background: var(--titan-blue-1);
  border: 1px solid var(--grey-3);

  h6 {
    font-weight: var(--font-weight-900);
    font-size: var(--font-size-25);
    line-height: var(--line-height-120);
    letter-spacing: var(--letter-spacing--0-02);
    color: var(--dark);
    margin-bottom: 24px !important;
  }

  ol {
    margin: 0;
    padding-inline-start: 25px;
    list-style-type: decimal !important;
    counter-reset: item;
  }

  & > ol {
    padding-inline-start: 0;
  }

  li {
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-20);
    line-height: var(--line-height-150);
    color: var(--dark);
    display: block;

    &:before {
      content: counters(item, '.') '. ';
      counter-increment: item;
      margin: 6px 0;
      margin-right: 5px;
    }
  }

  h3 {
    margin: 6px 0 !important;
    display: inline-block;
    letter-spacing: normal;

    a {
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-20);
      line-height: var(--line-height-150);
      color: var(--titan-blue-3);
    }
  }

  @media (max-width: 767px) {
    padding: 20px 20px 8px;

    h6 {
      font-size: var(--font-size-22);
      margin-bottom: 15px !important;
    }

    ol {
      padding-inline-start: 15px;
    }

    li {
      font-size: var(--font-size-16);
    }

    p {
      a {
        font-size: var(--font-size-16);
      }
    }
  }
`;

export const styledOL = styled.ol`
  margin-top: 20px;
`;

export const StyledLi = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
`;

export const TitleCSS = css`
  font-size: var(--font-size-22);
  h1 {
    font-size: var(--font-size-44);
  }
  h2 {
    font-size: var(--font-size-35);
  }
  h3 {
    font-size: var(--font-size-25);
  }
`;

export const H1 = styled.h1`
  ${TitleCSS}
`;
export const H2 = styled.h2`
  ${TitleCSS}
`;
export const H3 = styled.h3`
  ${TitleCSS}
`;
export const H4 = styled.h4`
  ${TitleCSS}
`;
export const H5 = styled.h5`
  ${TitleCSS}
`;
export const H6 = styled.h6`
  ${TitleCSS}
`;
