import React, { useState, useEffect } from 'react';
import Progress from 'components/Core/Progress';
import StyledRichText from 'components/RichText/StyledRichText';
import Cookies from 'js-cookie';
import TableOfContents from '../TableOfContents';

import { ContentKicker, ContentLayout, ContentTitle } from '../styles';

const PlaybookDetailsContent = ({ section, sectionInfo, playbookContentfulId }) => {
  const { current_section, current_chapter, total_sections } = sectionInfo;
  const [percentComplete, setPercent] = useState(0);

  useEffect(() => {
    if (Cookies.get(playbookContentfulId)) {
      const playbook_progress = JSON.parse(Cookies.get(playbookContentfulId));
      const percent =
        100 * (playbook_progress[current_chapter].reduce((x, y) => x + y) / total_sections);
      setPercent(percent);
    }
  }, []);

  let toc;
  let body;
  const sectionContent = JSON.parse(section?.content?.raw);

  if (
    sectionContent?.content[0]?.content[0]?.value?.toLowerCase()?.includes('table of content') &&
    sectionContent?.content[1]?.nodeType === 'ordered-list'
  ) {
    toc = {
      raw: JSON.stringify({ content: sectionContent.slice(0, 2), data: {}, nodeType: 'document' }),
    };
    body = {
      ...section?.content,
      raw: JSON.stringify({
        content: sectionContent.slice(2, sectionContent.length),
        data: {},
        nodeType: 'document',
      }),
    };
  }

  return (
    <>
      <Progress
        value={percentComplete}
        height={10}
        strokeColor="var(--titan-blue-3)"
        borderRadius={0}
        margin="0"
        noTransition
      />
      <ContentLayout backgroundColor="White">
        {toc && <TableOfContents content={toc} />}
        <ContentKicker>
          SECTION {current_section + 1} OF {total_sections}
        </ContentKicker>
        <ContentTitle>{section.title}</ContentTitle>
        <StyledRichText content={!toc ? section?.content : body} />
      </ContentLayout>
    </>
  );
};

export default PlaybookDetailsContent;
