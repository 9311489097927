import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import React from 'react';
import RichTextHyperLink from 'components/Core/HyperLink/RichTextHyperLink';
import { reduceContentValues } from 'utils/text';
import { ToC, StyledLi, H1, H2, H3, H4, H5, H6 } from '../styles';

const Bold = ({ children }) => (
  <span className="bold">
    <b>{children}</b>
  </span>
);
const Italic = ({ children }) => (
  <span className="bold">
    <em>{children}</em>
  </span>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node) => {
      return (
        <RichTextHyperLink href={node.data.uri}>
          {reduceContentValues(node?.content)}
        </RichTextHyperLink>
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <H3>{children}</H3>;
    },
    [BLOCKS.HEADING]: (node, children) => {
      return (
        <H1 className="blocks-heading-1" fontSize="big">
          {children}
        </H1>
      );
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return (
        <H2 className="blocks-heading-2" fontSize="normal">
          {children}
        </H2>
      );
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <H3 className="blocks-heading-3" fontSize="normal">
          {children}
        </H3>
      );
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return (
        <H4 className="blocks-heading-4" fontSize="normal">
          {children}
        </H4>
      );
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return (
        <H5 className="blocks-heading-5" fontSize="small">
          {children}
        </H5>
      );
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return (
        <H6 className="blocks-heading-6" fontSize="small">
          {children}
        </H6>
      );
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <StyledLi>{children}</StyledLi>;
    },
  },
};

const TableOfContents = ({ content }) => {
  return <ToC>{renderRichText(content, options)}</ToC>;
};

export default TableOfContents;
