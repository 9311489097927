import React from 'react';

import {
  StyledHero,
  PlaybookDetailsContainer,
  PlaybookDetailsHeroTitle,
  PlaybookDetailsHeroKicker,
  PlaybookDetailsNavigation,
  SectionNavigation,
  PlaybookDetailsHeroSubhead,
} from '../styles';

const PlaybookDetailsHero = ({ title, description, sectionInfo, sections, playbookHeroBG }) => {
  const { current_section, current_chapter, total_chapters, total_sections, splashPageSlug } =
    sectionInfo;

  return (
    <StyledHero playbookHeroBG={playbookHeroBG}>
      <PlaybookDetailsContainer className="hero-content-conatiner">
        <PlaybookDetailsHeroKicker>CHAPTER {current_chapter + 1}</PlaybookDetailsHeroKicker>
        <PlaybookDetailsHeroTitle className="font-h2">{title}</PlaybookDetailsHeroTitle>
        <PlaybookDetailsHeroSubhead>{description}</PlaybookDetailsHeroSubhead>
      </PlaybookDetailsContainer>
      <PlaybookDetailsNavigation id="playbook-hero-nav">
        <SectionNavigation
          to={current_section !== 0 && sections[current_section - 1]?.seo?.pageUrl}
          disabled={current_section === 0}
          shadow={false}
          type={'secondary'}
          button
        >
          Previous
        </SectionNavigation>
        <SectionNavigation
          to={
            current_section + 1 === total_sections
              ? `${splashPageSlug}#${`chapter${current_chapter + 1}`}`
              : sections[current_section + 1]?.seo?.pageUrl
          }
          type="secondary"
          button
          state={{ finishedChapter: current_chapter + 1 }}
        >
          {current_section + 1 === total_sections ? 'Finish Chapter' : 'Next Section'}
        </SectionNavigation>
      </PlaybookDetailsNavigation>
    </StyledHero>
  );
};

export default PlaybookDetailsHero;
