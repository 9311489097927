import React from 'react';
import { TableCard, TableCardTitle, TableProgress } from '../styles';

const TableOfContentsCard = ({ chapter, chapterNumber, progress }) => (
  <TableCard>
    <div className="table-card--inner-container">
      <TableCardTitle>
        {chapterNumber}. {chapter.title}
      </TableCardTitle>
      <TableProgress>
        {Math.floor(
          ((progress?.filter((section) => !!section).length || 0) / (progress?.length || 0)) * 100,
        )}
        % Completed
      </TableProgress>
    </div>
  </TableCard>
);

export default TableOfContentsCard;
