import { Col, Row } from 'components/Core/Grid';
import HyperLink from 'components/Core/HyperLink';
import Cookies from 'js-cookie';
import React from 'react';
import {
  updatePlaybookProgress,
  initPlaybookProgress,
} from 'components/Page/Playbook/utils/mapData';
import { TableOfContentsLayout, TableTitle } from '../styles';
import TableOfContentsCard from '../TableOfContentsCard';

const TableOfContentsProgress = ({ sectionInfo, chapters, playbookContentfulId }) => {
  updatePlaybookProgress(
    playbookContentfulId,
    chapters,
    sectionInfo.current_chapter,
    sectionInfo.current_section,
  );

  const playbook_progress = Cookies.get(playbookContentfulId)
    ? JSON.parse(Cookies.get(playbookContentfulId))
    : initPlaybookProgress(playbookContentfulId, chapters);

  const leftChapters = [...chapters];
  const rightChapters = [...chapters];
  const left = leftChapters.splice(0, Math.ceil(leftChapters.length / 2));
  const right = rightChapters.splice(Math.ceil(rightChapters.length / 2), rightChapters.length);
  return (
    <TableOfContentsLayout>
      <TableTitle>Table of Contents</TableTitle>
      <Row type="flex" gutter={40}>
        <Col lg={12} xs={24}>
          {left.map((chapter, index) => {
            let lastVisitedSection = playbook_progress[index].findIndex((section) => section === 0);
            lastVisitedSection = lastVisitedSection < 0 ? 0 : lastVisitedSection;

            return (
              <HyperLink key={index} href={chapter?.sections[lastVisitedSection]?.seo?.pageUrl}>
                <TableOfContentsCard
                  chapter={chapter}
                  chapterNumber={index + 1}
                  progress={playbook_progress[index]}
                />
              </HyperLink>
            );
          })}
        </Col>
        <Col lg={12} xs={24}>
          {right.map((chapter, index) => {
            const rightIndex = left.length + index;
            let lastVisitedSection = playbook_progress[rightIndex].findIndex(
              (section) => section === 0,
            );
            lastVisitedSection = lastVisitedSection < 0 ? 0 : lastVisitedSection;

            return (
              <HyperLink
                key={rightIndex}
                href={chapter?.sections[lastVisitedSection]?.seo?.pageUrl}
              >
                <TableOfContentsCard
                  chapter={chapter}
                  chapterNumber={rightIndex + 1}
                  progress={playbook_progress[rightIndex]}
                />
              </HyperLink>
            );
          })}
        </Col>
      </Row>
    </TableOfContentsLayout>
  );
};

export default TableOfContentsProgress;
